<template>
  <div class>
    <div class="d-flex align-items-center rounded shadow-sm p-2 m-1 text-truncate" v-background-3>
      <div class="mr-3">
        <BaseIcon
          name="circle"
          width="10"
          height="10"
          class="text-success m-auto"
          fill="#28a745"
          v-if="collaborateur.collaborateur_actif"
        ></BaseIcon>
        <BaseIcon
          name="circle"
          width="10"
          height="10"
          class="text-danger m-auto"
          fill="#dc3545"
          v-if="!collaborateur.collaborateur_actif"
        ></BaseIcon>
      </div>
      <div class="mr-2">
        <AppAvatar :url="collaborateur.collaborateur_avatar" propSize="3rem" ref="avatar"></AppAvatar>
      </div>
      <div class="text-truncate" style="line-height:1.1rem, width:100%;">
        <span class="mr-1">{{ collaborateur.collaborateur_nom }}</span>
        <span>{{ collaborateur.collaborateur_prenom }}</span>
        <contrat-viewer :contrat="contrat" :mode="1"></contrat-viewer>
      </div>
      <button-circle
        class="ml-auto"
        name="external-link"
        size="18"
        @click="$emit('show', collaborateur.id)"
      ></button-circle>
    </div>
  </div>
</template>
<script>
import ContratViewer from "@/components/collaborateurs/viewers/ContratViewer.vue";
import ButtonCircle from "@/components/bases/ButtonCircle.vue";
import AppAvatar from "@/components/bases/Avatar.vue";
import BaseIcon from "@/components/bases/Icon.vue";

export default {
  components: { ContratViewer, ButtonCircle, AppAvatar, BaseIcon },
  props: {
    collaborateur: {
      Type: Object,
      Requierd: true,
      Default: {},
    },
  },
  computed: {
    contrat: function () {
      return this.collaborateur.collaborateur_contrats_all;
    },
  },
};
</script>
<style lang="css"></style>
