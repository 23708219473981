<template>
  <div class>
    <div class>
      <span
        class="mr-1 mr-md-2 cursor-pointer hover-danger"
        @click="$emit('input', '')"
        :class="{ active: value === '' }"
        >Tous</span
      >
      <span
        class="mr-1 mr-md-2 cursor-pointer hover-danger"
        @click="$emit('input', 'A')"
        :class="{ active: value === 'A' }"
        >A</span
      >
      <span
        class="mr-1 mr-md-2 cursor-pointer hover-danger"
        @click="$emit('input', 'B')"
        :class="{ active: value === 'B' }"
        >B</span
      >
      <span
        class="mr-1 mr-md-2 cursor-pointer hover-danger"
        @click="$emit('input', 'C')"
        :class="{ active: value === 'C' }"
        >C</span
      >
      <span
        class="mr-1 mr-md-2 cursor-pointer hover-danger"
        @click="$emit('input', 'D')"
        :class="{ active: value === 'D' }"
        >D</span
      >
      <span
        class="mr-1 mr-md-2 cursor-pointer hover-danger"
        @click="$emit('input', 'E')"
        :class="{ active: value === 'E' }"
        >E</span
      >
      <span
        class="mr-1 mr-md-2 cursor-pointer hover-danger"
        @click="$emit('input', 'F')"
        :class="{ active: value === 'F' }"
        >F</span
      >
      <span
        class="mr-1 mr-md-2 cursor-pointer hover-danger"
        @click="$emit('input', 'G')"
        :class="{ active: value === 'G' }"
        >G</span
      >
      <span
        class="mr-1 mr-md-2 cursor-pointer hover-danger"
        @click="$emit('input', 'H')"
        :class="{ active: value === 'H' }"
        >H</span
      >
      <span
        class="mr-1 mr-md-2 cursor-pointer hover-danger"
        @click="$emit('input', 'I')"
        :class="{ active: value === 'I' }"
        >I</span
      >
      <span
        class="mr-1 mr-md-2 cursor-pointer hover-danger"
        @click="$emit('input', 'J')"
        :class="{ active: value === 'J' }"
        >J</span
      >
      <span
        class="mr-1 mr-md-2 cursor-pointer hover-danger"
        @click="$emit('input', 'K')"
        :class="{ active: value === 'K' }"
        >K</span
      >
      <span
        class="mr-1 mr-md-2 cursor-pointer hover-danger"
        @click="$emit('input', 'L')"
        :class="{ active: value === 'L' }"
        >L</span
      >
      <span
        class="mr-1 mr-md-2 cursor-pointer hover-danger"
        @click="$emit('input', 'M')"
        :class="{ active: value === 'M' }"
        >M</span
      >
      <span
        class="mr-1 mr-md-2 cursor-pointer hover-danger"
        @click="$emit('input', 'N')"
        :class="{ active: value === 'N' }"
        >N</span
      >
      <span
        class="mr-1 mr-md-2 cursor-pointer hover-danger"
        @click="$emit('input', 'O')"
        :class="{ active: value === 'O' }"
        >O</span
      >
      <span
        class="mr-1 mr-md-2 cursor-pointer hover-danger"
        @click="$emit('input', 'P')"
        :class="{ active: value === 'P' }"
        >P</span
      >
      <span
        class="mr-1 mr-md-2 cursor-pointer hover-danger"
        @click="$emit('input', 'Q')"
        :class="{ active: value === 'Q' }"
        >Q</span
      >
      <span
        class="mr-1 mr-md-2 cursor-pointer hover-danger"
        @click="$emit('input', 'R')"
        :class="{ active: value === 'R' }"
        >R</span
      >
      <span
        class="mr-1 mr-md-2 cursor-pointer hover-danger"
        @click="$emit('input', 'S')"
        :class="{ active: value === 'S' }"
        >S</span
      >
      <span
        class="mr-1 mr-md-2 cursor-pointer hover-danger"
        @click="$emit('input', 'T')"
        :class="{ active: value === 'T' }"
        >T</span
      >
      <span
        class="mr-1 mr-md-2 cursor-pointer hover-danger"
        @click="$emit('input', 'U')"
        :class="{ active: value === 'U' }"
        >U</span
      >
      <span
        class="mr-1 mr-md-2 cursor-pointer hover-danger"
        @click="$emit('input', 'V')"
        :class="{ active: value === 'V' }"
        >V</span
      >
      <span
        class="mr-1 mr-md-2 cursor-pointer hover-danger"
        @click="$emit('input', 'W')"
        :class="{ active: value === 'W' }"
        >W</span
      >
      <span
        class="mr-1 mr-md-2 cursor-pointer hover-danger"
        @click="$emit('input', 'X')"
        :class="{ active: value === 'X' }"
        >X</span
      >
      <span
        class="mr-1 mr-md-2 cursor-pointer hover-danger"
        @click="$emit('input', 'Y')"
        :class="{ active: value === 'Y' }"
        >Y</span
      >
      <span
        class="cursor-pointer hover-danger"
        @click="$emit('input', 'Z')"
        :class="{ active: value === 'Z' }"
        >Z</span
      >
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: ["value"],
  methods: {}
};
</script>
<style lang="css"></style>
